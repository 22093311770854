<template>
    <div class="row">
            <span class="col-sx-12 col-sm-12 col-md-12 d-sm-block d-md-block d-sx-block d-lg-none questionListBtn" v-if="selectedquestion" @click="openNav()">&#9776; Questions</span>
        <div id="mySidenav" class="questionList col-lg-4 col-xl-3 col-md-12 col-sx-12 col-sm-12" :class="{'sidenav':selectedquestion}">
            <div class="questionList col-sx-12 col-sm-12 col-md-12 d-sm-block d-sx-block d-md-block d-lg-none pb-3 pt-3 mobileQuestTitle" >
                <a v-if="selectedquestion" class="closebtn" @click="closeNav()">&times;</a>
            <h4>Describe Image
                    <b-icon  shift-v="1" scale="1" icon="mic-fill" style="padding-left:2px;"></b-icon>
                </h4>
            </div>
            <app-IntervalList ref="intervalList" :questionLists="questionLists" :questionId="'graph_id'"/>
        </div>
        <div class="questonhelp col-lg-8 col-xl-9 d-none d-lg-block" v-if="!selectedquestion">
            <h4 class="text-center mb-4 fancy"><span>Describe Image</span></h4>
            <h5 class="mb-1">Introduction</h5>
            <p class="para mb-2">You will see a image on screen. In 25 seconds, you have to describe the displayed image in detail. You will have 40 seconds to give your response.</p>
            <h5 class="mb-1">Skills assessed</h5>
            <p class="heading mb-2">Speaking</p>
            <h5 class="mb-1">Number of questions</h5>
            <p class="para mb-2"> 3 - 4 </p>
            <template v-if="user_type == 'premium'">
            <h5 class="mb-1">KEY POINTS</h5>
            <ul class="ml-5">
                <li>You have 25 seconds to study the image and prepare yourself with content. Once the given time is over, speak into the microphone immediately as you’ll hear a short tone.</li>
                <li>Don't speak before the microphone starts because your voice won’t be recorded. Speak clearly, and don’t make any hurry. You’ll be able to record your answer only once.</li>
                <li>Finish speaking before the progress bar reaches to the end. The word ‘Recording’ will turn into ‘Completed’.</li>
                <li>You will be judged for speaking, oral fluency, pronunciation and content.</li>
                <li>Maintain fluency and don’t hesitate. If the image has too much data to cover, just highlight the important points.</li>
            </ul>
            </template>
        </div>
        <div class="questiondesc col-lg-8 col-xl-9 col-md-12 col-sm-12 col-sx-12" :class="{'col-12':(selectedquestion.graph_id != 0)}" v-if="selectedquestion" center>
            <b-overlay class="fixedHeight" :show="show" rounded="sm" >
            <h4>Describe Image
                <b-icon  shift-v="1" scale="1" icon="mic-fill" style="padding-left:2px;"></b-icon>
            </h4>
            <p class="questHeading">Look at the graph below. In 25 seconds, please speak into the microphone and describe in detail what the graph is showing. You will have 40 seconds to give your response.</p>
            <h4 class="heading">{{selectedquestion.title}}</h4>
           
            <div class="row mb-2">
            <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 titleDiv">
                 <div class="prepare" ><p>{{title}} </p><app-Timer ref="timer"/></div>
            </div>
            <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 text-right pr-0" >
            <app-QuestionStatus ref="QuestionResetStatus" :selectedQuestion="selectedquestion" :question_id="selectedquestion.graph_id" :idName="'graph_id'" :tableName="'tblDescribeGraph'"/>
            </div>
            </div>
            <img class="describeImage" v-show="isLoaded" :src="image" @load="imageLoaded" />
            <img class="loadingImg" v-show="!isLoaded" src="@/assets/images/loading.gif" style="width:30%!important"/>
            <p class="recordMessage">{{recordMessage}}</p>
            <div class="record-settings">
              <audio-recorder
                    :mic-failed="micCheck"
                    :before-recording="beforeRecording"
                    :after-recording="afterRecording"
                    :pause-recording="pauseRecording"
                    :src=null
                    :time=".666"
                    />
            </div>
            <div class="recorded-audio">
              <div v-for="(record, index) in recordings" :key="index" class="row recorded-item">
                <div class="audio-container col-12 left"><audio :src="record.src" controls /></div>
                <div class="col-12"><p style="padding-left:0px!important">AI Scoring is available after submission.</p></div>
              </div>
            </div>
            </b-overlay>
            <div class="row">
                <div class="col-lg-12 col-xl-6 col-md-12 text-left col-sm-12 questBtnDiv mt-5 mb-2">
                    <b-button class="mr-2 pl-4 pr-4" :disabled="!this.recordStop || checkAutoNext" variant="outline-info" @click="showAnswerV9Marking()">Submit</b-button>
                    <!-- <b-button class="mr-2 pl-4 pr-4" variant="outline-info" @click="showAnswerNew()">Submit</b-button> -->
                    <b-button :disabled="checkAutoNext" variant="outline-warning" class="mr-2 pl-4 pr-4" @click="redoQuest()">Re-do</b-button>
                    <b-button :disabled="apiResults ==null || checkAutoNext" variant="outline-success" class="mr-2 pl-4 pr-4" v-b-modal.result-Modal>
                        <b-spinner v-if="resultStatus=='In-Process'" small type="grow"></b-spinner>
                        {{resultStatus}}
                    </b-button>
                    <toggle-button :width="75" :height="28" v-model="checkAnswerFlag" :value="false" :color="{checked: '#104e94', unchecked: '#e9ecef', disabled: '#CCCCCC'}" :speed="500" :sync="true" :labels="{checked: 'Answer', unchecked: 'Answer'}"/>
                    <span  > | 
                  <toggle-button :width="100" :height="28" v-model="checkAutoNext" :value="false" :color="{checked: '#368f5b', unchecked: '#cae8d6', disabled: '#CCCCCC'}" :speed="500" :sync="true" :labels="{checked: 'Auto-Next', unchecked: 'Manual-Next'}"/>
               </span>
                </div>
                <div class="col-6 text-right mt-3 pl-0 d-none d-xl-block mt-5 ">
                    <div class="row">
                        <div class="col-2 p-0">
                             <b-button :disabled="currentQuestion <= 1" @click="$refs.intervalList.changeQuestion('prev')" variant="outline-info" class="mr-2 prevQuestion pl-3 pr-3">Prev</b-button>
                        </div>
                        <div class="col-8 p-0">
                            <b-input-group>
                                <b-input-group-prepend>
                                    <b-icon shift-v="1.2" scale="1.3" icon="search"></b-icon>
                                </b-input-group-prepend>
                                <b-form-input v-model="searchKey" type="search" placeholder="Question title"></b-form-input>
                                <b-input-group-append>
                                <b-button @click="searchQuestion()" variant="outline-secondary">Search</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </div>
                        <div class="col-2 p-0 text-left">
                             <!-- <b-button :disabled="currentQuestion <= 1" @click="$refs.intervalList.changeQuestion('prev')" class="mr-2 prevQuestion pl-3 pr-3">Prev Quest</b-button> -->
                        <b-button @click="$refs.intervalList.changeQuestion('next')" variant="outline-info" class="ml-2 nextQuestion pl-3 pr-3">Next</b-button>
                        </div>
                    </div>
                </div>
                <div class="col-12 text-center mt-4" v-if="checkAnswerFlag">
                <h4 class="decorated" style="text-transform:capitalize;"><span>Answer</span></h4>
                    <div class="col-12 mb-2 mt-2 results text-left" >
                    <h5 style="text-transform:capitalize;">Transcript:- </h5>
                    <p style="display:block;padding-bottom: 0px !important;">{{actualAnswer}}</p>
                    </div>
                </div>
            </div>
        </div>
         <b-modal id="result-Modal"  ref="result-Modal" size="lg" centered hide-footer title="AI Result">
         <b-container class="resultModel" >
               <app-ResultPresent :apiResults="apiResults"/>
         </b-container>
         <b-button v-if="!checkAutoNext" class="mt-3" variant="outline-dark" left @click="$bvModal.hide('result-Modal')">Close Me</b-button>
         <b-button v-if="checkAutoNext" class="mt-3" variant="outline-dark" left >Disappear in {{ closeCounter }} seconds</b-button>
       </b-modal>
       <b-modal ref="search-Result" size="lg" scrollable centered hide-footer title="Search Result" ok-only ok-title="Cancel" ok-variant="secondary">
             <b-container v-if="searchKey!='' | searchQuestionList!=null"><app-IntervalList name="search" :popup="true" :parentFunctionCalling="getQuestionDetail" :questionLists="searchQuestionList" :questionId="'graph_id'"/></b-container>
        </b-modal>
        <div class="col-12 mt-5 d-none d-lg-block" v-if="selectedquestion!=null">
            <app-DiscussionTab ref="discussionTab" :question_id="currentQuestion" :tableName="'tblUserDescribeGraph'"/>
        </div>
    </div>
</template>
<script>
import { mapActions,mapGetters } from 'vuex'
import Timer from '../reuseable/timer'
// import blobToBase64 from 'blob-to-base64'
import $ from "jquery";
import ResultPresent from '../reuseable/resultPresent'
//import LocalStorageService from '../../store/LocalStorageService';
const sdk = require("microsoft-cognitiveservices-speech-sdk");
import IntervalList from '../reuseable/intervalList'
import QuestionStatus from '../reuseable/questStatus'
import DiscussionTab from '../reuseable/discussionTab'
import {bus} from '../../main'
export default {
    components:{
        'app-Timer':Timer,
        'app-IntervalList': IntervalList,
        'app-ResultPresent':ResultPresent,
        'app-QuestionStatus':QuestionStatus,
        'app-DiscussionTab':DiscussionTab
    },
    data(){
        return{
            questionLists:{
                type: Object,
                required: true
            },
            show:false,
            isLoaded:false,
            selectedquestion: null,
            image: null,
            recordMode:'press',
            recordings: [],
            selectedTime: 0,
            timeLeft: '00:00',
            startFlag:false,
            title:'Prepare',
            userAnswerResponse:null,
            userAnswer:{
                user_id:0,
                graph_id:0,
                recording:null,
                transcript:'',
                user_result:''
            },
            recordStop:false,
            actualAnswer:'',
            checkAnswerFlag:false,
            marksGot:0,
            user_type: 'Free',
            currentQuestion:0,
            recordMessage:'Click to Start',
            recordingBlob:null,
            resultStatus:'AI-Result',
            apiResults:null,
            microsoftTranscript:'',
            recognizer:null,
            searchKey:'',
            searchQuestionList:null,
            transcript:'',
            currentUser:null,
            keywordList:'',
            closeCounter:5,
            closeTime:null,
            checkAutoNext:false
        }
    },
    methods:{
        ...mapActions({
            questionservice: 'speakmodule/fetchQuestion',
            getTranscripty:'speakmodule/getTranscripty',
            saveAnswer: 'speakmodule/saveAnswer',
            generateFileUpload:'speakmodule/generateFileUpload',
            fetchUserUsage:'auth/saveDashBoardData',
            fetchQuestionPractice:'auth/fetchGraphData'
        }),
        ...mapGetters({
            getQuestionList: 'speakmodule/questions',
            answerResponse: 'speakmodule/AnswerResponse',
            GetUserUsageResponse:'auth/getDashBoardResponse',
            QuestionResponse: 'auth/UPDATEQuestionStatus',
            GetQuestionPractice:'auth/getPracticeNumber'
        }),
        openNav(){
            document.getElementById("mySidenav").style.width = "100%";
        },
        closeNav(){
            document.getElementById("mySidenav").style.width = "0";
        },
        searchQuestion(){
            const key=this.searchKey.trim().toLowerCase()
            const searchedQuestion= this.questionLists.filter((item)=>{
                return item.title.toLowerCase().includes(key)
            });
            this.searchKey = '';
            this.$refs.timer.pauseTime()
            ////console.log(searchedQuestion)
            if(searchedQuestion.length>0){
                if(searchedQuestion.length>1){
                   //console.log(searchedQuestion)
                   this.searchQuestionList=searchedQuestion
                   this.$refs['search-Result'].show()
                }
                else{
                    //console.log(searchedQuestion)
                    return this.getQuestionDetail(searchedQuestion[0].graph_id)
                }
            }
            else{
                return this.$alert("No record found with the given title", "Search Result");
            }
        },
        updateSelectQuestionStatus(question,id){
            ////console.log(this.selectedquestion)
            this.selectedquestion = question
            const currectQuestionIndex=this.questionLists.findIndex(element=>
            element.graph_id==id)
            this.questionLists[currectQuestionIndex]=this.selectedquestion
            ////console.log(question)
        },
        getQuestionDetail(questionId){
            //debugger
             if(questionId==0) return this.selectedquestion=null
            if(this.$refs['search-Result'].isVisible) this.$refs['search-Result'].hide()
            this.currentQuestion=questionId
            //console.log(questionId)
            this.recordMessage='Click to Start'
            this.recordingBlob =null
            this.resultStatus='AI-Result'
            this.show=false
            ////console.log(questionId)
            this.checkAnswerFlag = false
            this.actualAnswer = ''
            this.userAnswer.graph_id = questionId;
            this.selectedquestion = this.questionLists.filter(function (item) {
                    return item.graph_id == questionId;
                })[0];
                
            if(this.$refs.QuestionResetStatus)
            this.$refs.QuestionResetStatus.resetQuestionDetails(this.selectedquestion,questionId)
            this.actualAnswer = this.selectedquestion.transcript
            //////console.log(this.selectedquestion);
            //this.questionservice('configuregraph/blobId='+questionId).then(()=>{
                this.image = this.selectedquestion.blb //this.getQuestionList().data.blb;
                this.recordings = []
                this.startFlag = false
                this.userAnswerResponse ='' 
                this.recordStop= false
                this.isLoaded=false
                this.$nextTick(function(){
                    this.$refs.discussionTab.updateQuestionId(questionId)
                   
                    this.title = 'Time'
                    
                this.$refs.timer.setTime(0)
                this.$refs.timer.pauseTime()
                    $('.ar-recorder__stop').click()
                });
            //}); 
            
            
        },
        imageLoaded(){
             this.$refs.timer.setTime(25)
             this.title = 'Prepare'
             this.isLoaded=true
        },
        userUsage(){
        var data={
            user_id: this.currentUser.user_id,
            url:'users/userUsageCount'
          }
          this.fetchUserUsage(data).then(()=>{
            ////console.log(this.GetUserUsageResponse())
           this.currentUser.speaking_Counter= this.GetUserUsageResponse().data.speakingCount
            //this.$ls.remove('PackageType')
          })
        },
        getQuestionKeyWords(question_id){
            const url='configuregraph/getGraphKeywordsById='+question_id;
          this.questionservice(url).then(()=>{
            //console.log(url)
                const response=this.getQuestionList()
                this.keywordList=response.data;
                //console.log(response)
                
            })
              
        },
        checkUserAccess(){
             const user =this.currentUser
            if(user.user_type == 'Free' & user.speaking_Counter>=5)
            {
                return false
            }
            else if(user.user_type == 'standard' & user.speaking_Counter>=10)
            {
                return false
            }
            else{
                this.currentUser.speaking_Counter++
                return true
            }
        },
        redoQuest(){
            this.getQuestionDetail(this.currentQuestion)
            this.imageLoaded()
            //this.getQuestionDetail(this.selectedquestion.graph_id)
            //this.isLoaded=true; 
        },
        playBeep(){
            var sound = document.getElementById("beepAudio");
            sound.playbackRate = 0.5;
            sound.play()
        },
        pauseRecording(){
            $('.ar-recorder__stop').click()
        },
        wait(ms){
            var start = new Date().getTime();
            var end = start;
            while(end < start + ms) {
                end = new Date().getTime();
            }
        },
        showAnswer(){
            if(!this.checkUserAccess())
        {
            return this.$alert(
                "Please upgrade your plan for further practice your today limit is over",
                "Warning",
                "warning",
                {
                    confirmButtonText: "Cannot Proceed"
                })
        }
        this.getQuestionKeyWords(this.selectedquestion.graph_id);
            // this.checkAnswerFlag =true
            // this.actualAnswer = this.selectedquestion.transcript
            const fileName='UserDescribeImage--u'+this.userAnswer.user_id+'_q'+this.selectedquestion.graph_id+"_track"+Date.now()+'.wav'
            var file = new File([this.recordingBlob], fileName);
            //this.recordedFile = file
            var fd = new FormData()
            fd.append('files', file,fileName)
            // this.generateFileUpload(fd).then((message)=>{
            //     //console.log(message)
            // })
            var filePath='audio/UserDescribeImage/'+fileName.replace('--','')
            this.userAnswer.recording = filePath
            this.resultStatus='In-Process'
            this.show=true
            ////console.log(param)
            var userdata =this.$ls.get('user')
            this.userAnswer.user_id = userdata.user_id
            this.generateFileUpload(fd).then(()=>{
                //console.log(this.answerResponse())
                        if(this.answerResponse()=="Success"){
                            //console.log(this.microsoftTranscript)
                             //console.log('start delay '+ $.now())
                                this.wait(7000);
                            //console.log('start delay '+ $.now())
                            if(this.microsoftTranscript == '') this.wait(5000)
                            //console.log(this.microsoftTranscript)
                                var param={
                                    user_audio_file: filePath.replace('audio/',''),
                                    text: this.microsoftTranscript,
                                    user_id: this.userAnswer.user_id,
                                    quest:'u'+this.userAnswer.user_id+'/q'+this.selectedquestion.graph_id,
                                    url:'speechace/transcript'
                                }
                                //console.log(param)
                            this.getTranscripty(param).then(()=>{
                                if(this.answerResponse().data.success)
                                {
                                    if(this.answerResponse().data.result.status!='success'){
                                        this.resultStatus='No-Result'
                                        this.show=false
                                        return this.$alert('No recording found, Please retry.')
                                        
                                    }
                                    const res=this.userAnswer.transcript=this.answerResponse().data.result
                                    // var apiRes={
                                    //     correct_Words:res.text_score.fluency.overall_metrics.correct_word_count,
                                    //     word_Score_List:res.text_score.word_score_list
                                    // }
                                    // this.generateTranscriptFromRestult(apiRes)
                                    //console.log(res)
                                    var content = 0
                                    var conculsionWords=['Finally', 'In a word', 'In brief', 'Briefly', 'In conclusion','Conclusion', 'In the end', 'In the final analysis', 'on the Whole'
            , 'thus', 'to conclude', 'to summarize', 'in sum', 'to sum up', 'in summary','As Can Be Seen','After All','By And Large'
            ,'Generally Speaking','In Fact','To Sum Up','In The Final Analysis','On The Whole','All Things Considered'
            ,'In Any Event','As Shown Above','In Short','In Either Case','In The Long Run','In Brief','All In All'
            ,'Given These Points','In Essence','As Has Been Noted','In A Word','On Balance','For The Most Part'
            ,'Altogether', 'Obviously', 'Overall', 'Ultimately', 'Ordinarily', 'Definitely', 'and Usually',
            'as can be seen','generally speaking','All things considered','as shown above','In the long run','Given these points'
            ,'as has been noted','For the most part','As discussed above'];
                                    const duration=Math.round(res.text_score.fluency.overall_metrics.duration- res.text_score.fluency.overall_metrics.all_pause_duration)
                                    // if(duration>=20 & duration<=38) content=80
                                    // else if(duration<20) content=60
                                    // else if(duration>38) content=60
                                    // else content = 10

                                    let colorsArray=["red","blue","black","orange","green","purple","yellow","pink","white"];

                                    var checkConclusion=0
                                    var correctWords=0
                                    //console.log("KeyWords");
                                    //console.log(this.keywordList)
                                    var array=this.microsoftTranscript.split(' ')
                                    for (let index = 0; index < array.length; index++) {
                                        const conElement=array[index].replace(',','').replace('.','');
                                        const element = array[index].replace(',','').replace('.','').toLowerCase();
                                      
                                        if(this.keywordList.length>0){
                                            let found= this.keywordList.filter((item)=>item.keyword.indexOf(element));
                                            if(found>0) correctWords++;
                                        }
                                         
                                       if(colorsArray.indexOf(element)>-1) correctWords++;
                                       if(conculsionWords.indexOf(conElement)>-1) checkConclusion++
                                    }

                                    if(correctWords>=10) content=90
                                    else if(correctWords>=8)content=79
                                    else if(correctWords>=5)content=65
                                    else if(correctWords>=3)content=50
                                    else content =0

                                    

                                    if ( duration >= 32) content +=90
                                    else if (duration >= 25 ) content +=79
                                    else if ( duration >=20 ) content += 65
                                    else if( duration >= 15) content += 50
                                    else if ( duration >= 10 ) content += 30
                                    else content +=0

                                    //content +=((this.microsoftTranscript.split(this.selectedquestion.title)-1)>=2)? 5: 2;
                                    content +=(checkConclusion>0)?90:45;
                                    content=(correctWords>3)?content/3:content/2;
                                    if(content>90) content=90
                                    content=Math.round(content);
                                    this.resultStatus='AI-Result Ready'
                                    this.show=false
                                    this.apiResults={
                                        transcript:'',
                                        quality_Score:res.text_score.quality_score,
                                        total_Words:res.text_score.fluency.overall_metrics.word_count,
                                        correct_Words:res.text_score.fluency.overall_metrics.correct_word_count,
                                        vocab:res.text_score.fluency.overall_metrics.ielts_subscore.vocab,
                                        pte_Estimate:res.text_score.fluency.overall_metrics.pte_estimate,
                                        word_Score_List:res.text_score.word_score_list,
                                        content_Score:content,
                                        textArray:'',
                                        text:'',
                                        src:filePath,
                                        type:'all'
                                    }
                                    //console.log(this.apiResults)
                                    var proScore =(this.apiResults.quality_Score>10)?this.apiResults.quality_Score-10:this.apiResults.quality_Score
                                    
                                    var overAllScore =Math.round((this.apiResults.pte_Estimate+proScore+this.apiResults.content_Score)/3)
                                    //this.$refs['result-Modal'].show()
                                    this.userAnswer.url='usergraphrecording/createrecording'
                                    this.userAnswer.user_result='Your Score: '+overAllScore+'/90'
                                    this.userAnswer.transcript=this.answerResponse().data.result
                                    this.saveAnswer(this.userAnswer).then(()=>{
                                            //console.log(this.answerResponse().data)
                                            //vm.userAnswerResponse = vm.answerResponse().data
                                        })
                                }
                                //vm.userAnswerResponse = vm.answerResponse().data
                            })
                        }
                    })
        },
        showAnswerV9(){
            try{

            
                if(!this.checkUserAccess())
            {
                return this.$alert(
                    "Please upgrade your plan for further practice your today limit is over",
                    "Warning",
                    "warning",
                    {
                        confirmButtonText: "Cannot Proceed"
                    })
            }
        this.getQuestionKeyWords(this.selectedquestion.graph_id);
            // this.checkAnswerFlag =true
            // this.actualAnswer = this.selectedquestion.transcript
            const fileName='UserDescribeImage--u'+this.userAnswer.user_id+'_q'+this.selectedquestion.graph_id+"_track"+Date.now()+'.wav'
            var file = new File([this.recordingBlob], fileName);
            //this.recordedFile = file
            var fd = new FormData()
            fd.append('files', file,fileName)
            // this.generateFileUpload(fd).then((message)=>{
            //     //console.log(message)
            // })
            var filePath='audio/UserDescribeImage/'+fileName.replace('--','')
            this.userAnswer.recording = filePath
            this.resultStatus='In-Process'
            this.show=true
            ////console.log(param)
            var userdata =this.$ls.get('user')
            this.userAnswer.user_id = userdata.user_id
            this.generateFileUpload(fd).then(()=>{
                //console.log(this.answerResponse())
                if(this.answerResponse()==undefined){
                                this.resultStatus='No-Result'
                                        this.show=false
                                        return this.$alert('No recording found, Please retry.')
                            }
                       else if(this.answerResponse()=="Success"){
                            //console.log(this.microsoftTranscript)
                             //console.log('start delay '+ $.now())
                                this.wait(7000);
                            //console.log('start delay '+ $.now())
                            if(this.microsoftTranscript == '') this.wait(5000);

                            if(this.microsoftTranscript == '') {
                                this.resultStatus='No-Result'
                                        this.show=false
                                        return this.$alert('No recording found, Please retry.')
                            }
                            //console.log(this.microsoftTranscript)
                                var param={
                                    user_audio_file: filePath.replace('audio/',''),
                                    text: this.microsoftTranscript,
                                    user_id: this.userAnswer.user_id,
                                    quest:'u'+this.userAnswer.user_id+'/q'+this.selectedquestion.graph_id,
                                    url:'speechace/transcriptNew'
                                }
                                //console.log(param)
                            this.getTranscripty(param).then(()=>{
                            debugger;
                            if(this.answerResponse().data==null){
                                this.resultStatus='No-Result'
                                        this.show=false
                                        return this.$alert('No recording found, Please retry.')
                            }    
                            else if(this.answerResponse().data.success)
                                {
                                    if(this.answerResponse().data.result.status!='success'){
                                        this.resultStatus='No-Result'
                                        this.show=false
                                        return this.$alert('No recording found, Please retry.')
                                        
                                    }
                                    const res=this.userAnswer.transcript=this.answerResponse().data.result
                                    // var apiRes={
                                    //     correct_Words:res.text_score.fluency.overall_metrics.correct_word_count,
                                    //     word_Score_List:res.text_score.word_score_list
                                    // }
                                    // this.generateTranscriptFromRestult(apiRes)
                                    //console.log(res)
                                    var content = 0
                                    var conculsionWords=['finally', 'in a word', 'in brief', 'briefly', 'in conclusion','conclusion', 'in the end', 'in the final analysis', 'on the whole'
            , 'thus', 'to conclude', 'to summarize', 'in sum', 'to sum up', 'in summary','As Can Be Seen','After All','By And Large'
            ,'Generally Speaking','In Fact','To Sum Up','In The Final Analysis','On The Whole','All Things Considered'
            ,'In Any Event','As Shown Above','In Short','In Either Case','In The Long Run','In Brief','All In All'
            ,'Given These Points','In Essence','As Has Been Noted','In A Word','On Balance','For The Most Part'
            ,'Altogether', 'Obviously', 'Overall', 'Ultimately', 'Ordinarily', 'Definitely', 'and Usually',
            'as can be seen','generally speaking','all things considered','as shown above','in the long run','given these points'
            ,'as has been noted','for the most part','As discussed above','picture','can','see','informative'];
                                    const duration=Math.round(res.text_score.fluency.overall_metrics.duration- res.text_score.fluency.overall_metrics.all_pause_duration)
                                    // if(duration>=20 & duration<=38) content=80
                                    // else if(duration<20) content=60
                                    // else if(duration>38) content=60
                                    // else content = 10

                                    let colorsArray=["red","blue","black","orange","green","purple","yellow","pink","white"];

                                    var checkConclusion=0
                                    var correctWords=0
                                    //console.log("KeyWords");
                                    //console.log(this.keywordList)
                                    var array=this.microsoftTranscript.split(' ')
                                    for (let index = 0; index < array.length; index++) {
                                        const element = array[index].replace(',','').replace('.','').toLowerCase();
                                      
                                        if(this.keywordList.length>0){
                                            for (let item of this.keywordList) {
                                               if(item.keyword.toLowerCase()==element) correctWords++;
                                            }
                                            // let found= this.keywordList.filter((item)=>item.keyword.indexOf(element));
                                            // if(found>0) correctWords++;
                                        }
                                        else{
                                            var transArray=this.selectedquestion.transcript.split(' ');
                                            transArray.forEach(transElement => {
                                                if(transElement.replace(',','').replace('.','').toLowerCase()==element) correctWords++;
                                           });
                                        }
                                         
                                       if(colorsArray.indexOf(element)>-1) correctWords++;
                                       if(conculsionWords.indexOf(element)>-1) checkConclusion++
                                    }

                                    if(correctWords>=12) content=90
                                    else if(correctWords>=10)content=79
                                    else if(correctWords>=8)content=65
                                    else if(correctWords>=6)content=50
                                    else content =10
//console.log(correctWords)
                                    if(content>10){
                                        if ( duration >= 32) content +=90
                                        else if (duration >= 25 ) content +=79
                                        else if ( duration >=20 ) content += 65
                                        else if( duration >= 15) content += 50
                                        else if ( duration >= 10 ) content += 30
                                        else content +=0

                                        //content +=((this.microsoftTranscript.split(this.selectedquestion.title)-1)>=2)? 5: 2;
                                        content +=(checkConclusion>0)?90:45;
                                        content=(correctWords>3)?content/3:content/2;
                                    }

                                    

                                   // content=(correctWords<3)? 10:content;

                                    if(content>90) content=90
                                    content=Math.round(content);
                                    this.resultStatus='AI-Result Ready'
                                    this.show=false
                                    this.apiResults={
                                        transcript:'',
                                        quality_Score:(content==10)?10:res.text_score.pte_score.pronunciation,
                                        total_Words:res.text_score.fluency.overall_metrics.word_count,
                                        correct_Words:res.text_score.fluency.overall_metrics.correct_word_count,
                                        vocab:res.text_score.pte_score.vocab,
                                        pte_Estimate:(content==10)?10:res.text_score.pte_score.fluency,
                                        word_Score_List:res.text_score.word_score_list,
                                        content_Score:content,
                                        textArray:'',
                                        text:'',
                                        src:filePath,
                                        type:'all'
                                    }
                                    //console.log(this.apiResults)
                                    var proScore =(this.apiResults.quality_Score>10)?this.apiResults.quality_Score-10:this.apiResults.quality_Score
                                    
                                    var overAllScore =Math.round((this.apiResults.pte_Estimate+proScore+this.apiResults.content_Score)/3)
                                    //this.$refs['result-Modal'].show()
                                    this.userAnswer.url='usergraphrecording/createrecording'
                                    this.userAnswer.user_result='Your Score: '+overAllScore+'/90'
                                    this.userAnswer.transcript=this.answerResponse().data.result
                                    this.saveAnswer(this.userAnswer).then(()=>{ 
                                            //console.log(this.answerResponse().data)
                                            //vm.userAnswerResponse = vm.answerResponse().data
                                        })
                                }
                                //vm.userAnswerResponse = vm.answerResponse().data
                            })
                        }
                    })
                }
                catch(e){
                    this.resultStatus='No-Result'
                    this.show=false
                    return this.$alert('No recording found, Please retry.')
                }
        },
        showAnswerV9Marking(){
            if(!this.checkUserAccess())
        {
            this.checkAnswerFlag=false;
            return this.$alert(
                "Please upgrade your plan for further practice your today limit is over",
                "Warning",
                "warning",
                {
                    confirmButtonText: "Cannot Proceed"
                })
        }
        this.getQuestionKeyWords(this.selectedquestion.graph_id);
            // this.checkAnswerFlag =true
            // this.actualAnswer = this.selectedquestion.transcript
            const fileName='UserDescribeImage--u'+this.userAnswer.user_id+'_q'+this.selectedquestion.graph_id+"_track"+Date.now()+'.wav'
            var file = new File([this.recordingBlob], fileName);
            //this.recordedFile = file
            var fd = new FormData()
            fd.append('files', file,fileName)
            // this.generateFileUpload(fd).then((message)=>{
            //     //console.log(message)
            // })
            var filePath='audio/UserDescribeImage/'+fileName.replace('--','')
            this.userAnswer.recording = filePath
            this.resultStatus='In-Process'
            this.show=true
            ////console.log(param)
            var userdata =this.$ls.get('user')
            this.userAnswer.user_id = userdata.user_id
            this.generateFileUpload(fd).then(()=>{
                //console.log(this.answerResponse())
                        if(this.answerResponse()=="Success"){
                            //console.log(this.microsoftTranscript)
                             //console.log('start delay '+ $.now())
                               // this.wait(7000);
                            //console.log('start delay '+ $.now())
                            if(this.microsoftTranscript == '') this.wait(5000)
                            if(this.microsoftTranscript == ''){
                                this.resultStatus='No-Result'
                                        this.show=false
                                        return this.$alert('No voice found in submitted recording, Please retry.')
                            }
                            //console.log(this.microsoftTranscript)
                                var param={
                                    user_audio_file: filePath.replace('audio/',''),
                                    text: this.microsoftTranscript,
                                    user_id: this.userAnswer.user_id,
                                    quest:'u'+this.userAnswer.user_id+'/q'+this.selectedquestion.graph_id,
                                    url:'speechace/transcriptNewMarking',
                                    questType:'DescribeImage',
                                    keyWords:this.keywordList,
                                    questionTranscript:this.selectedquestion.transcript
                                }
                                console.log(param)
                            this.getTranscripty(param).then(()=>{
                                if(this.answerResponse().data==undefined){
                                    this.resultStatus='No-Result'
                                        this.show=false
                                        return this.$alert('No voice found in submitted recording, Please retry.')
                                }
                                else if(this.answerResponse().data.success)
                                {
                                    if(this.answerResponse().data.result.status!='success'){
                                        this.resultStatus='No-Result'
                                        this.show=false
                                        return this.$alert('No voice found in submitted recording, Please retry.')
                                        
                                    }
                                    const res=this.userAnswer.transcript=this.answerResponse().data.result
                                    console.log(this.answerResponse());
                                    const markingResult=this.answerResponse().data.apiResults;
                                    // var content = 0
                                    // var conculsionWords=['finally', 'in a word', 'in brief', 'briefly', 'in conclusion','conclusion', 'in the end', 'in the final analysis', 'on the whole'
                                    //                     , 'thus', 'to conclude', 'to summarize', 'in sum', 'to sum up', 'in summary','As Can Be Seen','After All','By And Large'
                                    //                     ,'Generally Speaking','In Fact','To Sum Up','In The Final Analysis','On The Whole','All Things Considered'
                                    //                     ,'In Any Event','As Shown Above','In Short','In Either Case','In The Long Run','In Brief','All In All'
                                    //                     ,'Given These Points','In Essence','As Has Been Noted','In A Word','On Balance','For The Most Part'
                                    //                     ,'Altogether', 'Obviously', 'Overall', 'Ultimately', 'Ordinarily', 'Definitely', 'and Usually',
                                    //                     'as can be seen','generally speaking','all things considered','as shown above','in the long run','given these points'
                                    //                     ,'as has been noted','for the most part','As discussed above','picture','can','see','informative'];
                                    // const duration=Math.round(res.text_score.fluency.overall_metrics.duration- res.text_score.fluency.overall_metrics.all_pause_duration)
                                   

                                    // let colorsArray=["red","blue","black","orange","green","purple","yellow","pink","white"];

                                    // var checkConclusion=0
                                    // var correctWords=0
                                    // var array=this.microsoftTranscript.split(' ')
                                    // for (let index = 0; index < array.length; index++) {
                                    //     const element = array[index].replace(',','').replace('.','').toLowerCase();
                                      
                                    //     if(this.keywordList.length>0){
                                    //         for (let item of this.keywordList) {
                                    //            if(item.keyword.toLowerCase()==element) correctWords++;
                                    //         }
                                            
                                    //     }
                                    //     else{
                                    //         var transArray=this.selectedquestion.transcript.split(' ');
                                    //         transArray.forEach(transElement => {
                                    //             if(transElement.replace(',','').replace('.','').toLowerCase()==element) correctWords++;
                                    //        });
                                    //     }
                                         
                                    //    if(colorsArray.indexOf(element)>-1) correctWords++;
                                    //    if(conculsionWords.indexOf(element)>-1) checkConclusion++
                                    // }

                                    // if(correctWords>=12) content=90
                                    // else if(correctWords>=10)content=79
                                    // else if(correctWords>=8)content=65
                                    // else if(correctWords>=6)content=50
                                    // else content =10

                                    // if(content>10){
                                    //     if ( duration >= 32) content +=90
                                    //     else if (duration >= 25 ) content +=79
                                    //     else if ( duration >=20 ) content += 65
                                    //     else if( duration >= 15) content += 50
                                    //     else if ( duration >= 10 ) content += 30
                                    //     else content +=0

                                    //     content +=(checkConclusion>0)?90:45;
                                    //     content=(correctWords>3)?content/3:content/2;
                                    // }

                                    

                                    // if(content>90) content=90
                                    // content=Math.round(content);
                                    
                                    // this.apiResults={
                                    //     transcript:'',
                                    //     quality_Score:(content==10)?10:res.text_score.pte_score.pronunciation,
                                    //     total_Words:res.text_score.fluency.overall_metrics.word_count,
                                    //     correct_Words:res.text_score.fluency.overall_metrics.correct_word_count,
                                    //     vocab:res.text_score.pte_score.vocab,
                                    //     pte_Estimate:(content==10)?10:res.text_score.pte_score.fluency,
                                    //     word_Score_List:res.text_score.word_score_list,
                                    //     content_Score:content,
                                    //     textArray:'',
                                    //     text:'',
                                    //     src:filePath,
                                    //     type:'all'
                                    // }
                                    
                                    // var proScore =(this.apiResults.quality_Score>10)?this.apiResults.quality_Score-10:this.apiResults.quality_Score
                                    
                                     var overAllScore =markingResult.overAllScore; //Math.round((this.apiResults.pte_Estimate+proScore+this.apiResults.content_Score)/3)
                                    this.apiResults=markingResult;
                                     this.resultStatus='AI-Result Ready'
                                     this.show=false
                                    console.log(this.apiResults);
                                    this.userAnswer.url='usergraphrecording/createrecording'
                                    //this.userAnswer.mock_test_id=0;
                                    this.userAnswer.user_result='Your Score: '+overAllScore+'/90'
                                    this.userAnswer.transcript=this.answerResponse().data.result;
                                    if(this.checkAutoNext) {
                                        this.closeTime=null;
                                        this.closeCounter=5
                                        this.$refs['result-Modal'].show();
                                        this.startTime(); 
                                    }
                                    this.saveAnswer(this.userAnswer).then(()=>{ 
                                            //console.log(this.answerResponse().data)
                                            //vm.userAnswerResponse = vm.answerResponse().data
                                        })
                                }
                                //vm.userAnswerResponse = vm.answerResponse().data
                            })
                        }
                    })
        },

        showAnswerNew(){
            if(!this.checkUserAccess())
            {
                return this.$alert(
                    "Please upgrade your plan for further practice your today limit is over",
                    "Warning",
                    "warning",
                    {
                        confirmButtonText: "Cannot Proceed"
                    })
            }
            // this.checkAnswerFlag =true
            // this.actualAnswer = this.selectedquestion.transcript
            const fileName='UserDescribeImage--u'+this.userAnswer.user_id+'_q'+this.selectedquestion.graph_id+"_track"+Date.now()+'.wav'
            var file = new File([this.recordingBlob], fileName);
            //this.recordedFile = file
            var fd = new FormData()
            fd.append('files', file,fileName)
            // this.generateFileUpload(fd).then((message)=>{
            //     //console.log(message)
            // })
            var filePath='audio/UserDescribeImage/'+fileName.replace('--','')
            this.userAnswer.recording = filePath
            this.resultStatus='In-Process'
            this.show=true
            ////console.log(param)
            var userdata =this.$ls.get('user')
            this.userAnswer.user_id = userdata.user_id
            this.generateFileUpload(fd).then(()=>{
                //console.log(this.answerResponse())
                        if(this.answerResponse()=="Success"){
                            //console.log(this.microsoftTranscript)
                             //console.log('start delay '+ $.now())
                                this.wait(7000);
                            //console.log('start delay '+ $.now())
                            if(this.microsoftTranscript == '') this.wait(5000)
                            //console.log(this.microsoftTranscript)
                                var param={
                                    user_audio_file:filePath.replace('audio/',''),
                                    text: this.microsoftTranscript,
                                    user_id: this.userAnswer.user_id,
                                    quest:'u'+this.userAnswer.user_id+'/q'+this.selectedquestion.graph_id,
                                    url:'deepgram/deepgramTranscript'
                                }
                                //console.log(param)
                            this.getTranscripty(param).then(()=>{
                                var deepGramTranscriptJson=this.answerResponse().data.result.results.channels[0].alternatives[0];
                                //console.log(this.answerResponse().data.success)
                               //s this.answerResponse().data.result.metadata.duration
                                 if(this.answerResponse().data.success==1)
                                {
                                    
                                    const res=this.userAnswer.transcript=deepGramTranscriptJson
                                    // var apiRes={
                                    //     correct_Words:res.text_score.fluency.overall_metrics.correct_word_count,
                                    //     word_Score_List:res.text_score.word_score_list
                                    // }
                                    // this.generateTranscriptFromRestult(apiRes)

                                    //this.microsoftTranscript="In front of me, there is a picture which is regarding seven one nine thousand d. On the image, I can see hands on the image. I can see blue shirt on the image. I can see house on the image. I can see pen on the image. I can see red color on the image. I can see folder. On the image, I can see keys. On the image, I can see friendship. On the image, I can see gray color. On the image, I can see papers on the image, I can see so many things. Overall, the picture is very beautiful.";
                                    this.microsoftTranscript=(this.microsoftTranscript=="")?deepGramTranscriptJson.transcript:this.microsoftTranscript;
                                    //console.log(res)
                                    var content = 0
                                     var conculsionWords=['finally', 'in a word', 'in brief', 'briefly', 'in conclusion','conclusion', 'in the end', 'in the final analysis', 'on the whole'
                                        , 'thus', 'to conclude', 'to summarize', 'in sum', 'to sum up', 'in summary']
                                    const duration=Math.round(this.answerResponse().data.result.metadata.duration) //Math.round(res.text_score.fluency.overall_metrics.duration- res.text_score.fluency.overall_metrics.all_pause_duration)
                                    
                                    if ( duration >= 32) content +=90
                                    else if (duration >= 25 ) content +=79
                                    else if ( duration >=20 ) content += 65
                                    else if( duration >= 15) content += 50
                                    else if ( duration >= 10 ) content += 30
                                    else content +=0

                                    
                                    if(duration>=20 & duration<=38) content=80
                                    else if(duration<20) content=60
                                    else if(duration>38) content=60
                                    else content = 10
                                    var checkConclusion=0
                                    var array=this.microsoftTranscript.split(' ')
                                    for (let index = 0; index < array.length; index++) {
                                        const element = array[index].replace(',','').replace('.','');
                                        if(conculsionWords.indexOf(element)>-1) checkConclusion++
                                    }
                                    //content +=((this.microsoftTranscript.split(this.selectedquestion.title)-1)>=2)? 5: 2;
                                    content +=(checkConclusion>0)?10:5;
                                    this.resultStatus='AI-Result Ready'
                                    this.show=false
//debugger
                                    var listWords=deepGramTranscriptJson.words;
                                    //console.log(listWords);
                                    var contCorrectWords=0;
                                    var contWrongWords=0;
                                    var pteEstimate=0;
                                    var questionText=this.microsoftTranscript;
                                    var actualIndex=0;
                                    $.each(listWords.reverse(),(index,item)=>{
                                        //pteEstimate+=item.confidence;
                                    if(questionText.indexOf(item.punctuated_word)!=-1) {
                                        contCorrectWords++;
                                        pteEstimate+=item.confidence;
                                    }
                                    else {
                                        contWrongWords++;
                                        listWords[actualIndex].confidence=0;
                                        pteEstimate-=item.confidence*1;
                                    }
                                    actualIndex++;
                                    });
                                    if(contCorrectWords<5)content=0;
                                    const totalWords=array.length;
                                    const correctWords=(contCorrectWords-contWrongWords>1)?(contCorrectWords-contWrongWords):0 ;
                                    pteEstimate=Math.round((pteEstimate/totalWords)*100)-10;
                                    pteEstimate=(pteEstimate<1)?0:pteEstimate;
                                    if(deepGramTranscriptJson.transcript=="")content=0;
                                    this.apiResults={
                                        transcript:'',
                                        quality_Score:(contCorrectWords/(contCorrectWords+contWrongWords)*100).toFixed(0),//res.text_score.quality_score,
                                        total_Words:array.length, //res.text_score.fluency.overall_metrics.word_count,
                                        correct_Words:correctWords,//res.text_score.fluency.overall_metrics.correct_word_count,
                                        vocab:pteEstimate,//res.text_score.fluency.overall_metrics.ielts_subscore.vocab,
                                        pte_Estimate:pteEstimate,//res.text_score.fluency.overall_metrics.pte_estimate,
                                        word_Score_List:listWords.reverse(),//res.text_score.word_score_list,
                                        content_Score:content,
                                        textArray:array,
                                        text:'',
                                        src:filePath,
                                        type:'all'
                                    }
                                    //console.log(this.apiResults)
                                    var proScore =(this.apiResults.quality_Score>10)?this.apiResults.quality_Score-10:this.apiResults.quality_Score
                                    
                                    var overAllScore =Math.round((this.apiResults.pte_Estimate+proScore+this.apiResults.content_Score)/3)
                                    //this.$refs['result-Modal'].show()
                                    this.userAnswer.url='usergraphrecording/createrecording'
                                    this.userAnswer.user_result='Your Score: '+overAllScore+'/90'
                                    this.userAnswer.transcript=this.answerResponse().data.result
                                    this.saveAnswer(this.userAnswer).then(()=>{
                                            //console.log(this.answerResponse().data)
                                            //vm.userAnswerResponse = vm.answerResponse().data
                                        })
                                }
                                else{
                                        this.resultStatus='No-Result'
                                        this.show=false
                                        return this.$alert('No recording found, Please retry.')
                                }
                                //vm.userAnswerResponse = vm.answerResponse().data
                            })
                        }
                    })
        },
        startTime()
        {
            //var tt;
            if(this.closeCounter==5 && this.closeTime==null){
                this.closeTime=setInterval(()=>{this.startTime()},1000);
                console.log(this.closeCounter)
            }
            else if(this.closeCounter == 0) {
                console.log(this.closeCounter)
                clearInterval(this.closeTime);
                this.$refs['result-Modal'].hide();
                this.$refs.intervalList.changeQuestion('next');
            } else {
                console.log(this.closeCounter)
            this.closeCounter--;
            }
        },
        _arrayBufferToBase64( buffer ) {
            var binary = '';
            var bytes = new Uint8Array( buffer );
            var len = bytes.byteLength;
            for (var i = 0; i < len; i++) {
                binary += String.fromCharCode( bytes[ i ] );
            }
            return window.btoa( binary );
        },
        removeRecord (index) {
            this.recordings.splice(index, 1)
        },
        timerEnd(){
            //////console.log(this.$refs)
            //this.$refs.recording.stream(new MediaStream)
           // this.$listeners.onStream()
           this.title = 'Time'
           if(!this.startFlag) $('.ar-icon.ar-icon__lg').click()
            //alert('Timer')
        },
        beforeRecording (msg) {
            this.microsoftTranscript=''
            
            //console.log('Before Record: ', msg)
            this.startFlag = false
            if(this.$refs.timer!=undefined)this.$refs.timer.setTime(0)
            this.title = 'Time'
            this.userAnswerResponse =''
            this.recordStop= false
            this.playBeep()
            this.recordMessage='Click to Stop'
            $('.ar-recorder__stop').addClass('upFront')
            this.fromMic()
        },
        afterRecording(msg){
            this.startFlag = true
            this.recognizer.stopContinuousRecognitionAsync();
            this.recordMessage='Done'
            ////console.log('After Record: ', msg)
            this.recordings=[]
            this.recordings.push({
            src: window.URL.createObjectURL(msg.blob)
            })
            this.$refs.timer.pauseTime()
            
            this.userAnswer.transcript= this.userAnswerResponse
            this.recordStop= true
            this.recordingBlob = msg.blob
            $('.ar-recorder__stop').removeClass('upFront')
            this.recognizer.stopContinuousRecognitionAsync();
            if(this.checkAutoNext) this.showAnswerV9Marking();

        },
        micCheck(msg){
            alert(msg)
        },
        checkRecordingPause(){
            if(this.recordMessage!='Done')
                {
                let myVar = setInterval(function() {
                    
                    if(this.microsoftTranscript!==this.microsoftTranscript & this.recordMessage!='Done')
                    {
                        //console.log('Inside Interval')
                        this.checkRecordingPause()
                    }
                    else{
                        //console.log('Interval stop')
                        clearInterval(myVar);
                        $('.ar-recorder__stop').click()
                    }
                }, 3000);
            }
        },
        fromMic() {
            //console.log('In Mic')
            const speechConfig = sdk.SpeechConfig.fromSubscription(process.env.VUE_APP_MICRO_API, process.env.VUE_APP_MICRO_SERVICEREGION);
            let audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput();
            //speechConfig.setProperty(sdk.PropertyId.SpeechServiceConnection_InitialSilenceTimeoutMs, "3000");
            this.recognizer = new sdk.SpeechRecognizer(speechConfig, audioConfig);
            const phraseList = sdk.PhraseListGrammar.fromRecognizer(this.recognizer);
            phraseList.addPhrase(this.selectedquestion.transcript);
            //console.log('Speak into your microphone.');


            this.recognizer.startContinuousRecognitionAsync();

            this.recognizer.recognizing = (s, e) => {
                if(e!=null)
                {
                     //this.microsoftTranscript =e.result.text;
                    //console.log(`RECOGNIZING sub : Text=${e.result.text}`);
                }
            };

            this.recognizer.recognized = (s, e) => {
                ////console.log(e.result)
                    if (e.result.reason == 3) {
                     this.microsoftTranscript +=e.result.text;
                     //this.checkRecordingPause()
                        //console.log(`RECOGNIZED final: Text=${e.result.text}`);
                    }
                    else if (e.result.reason == 0) {
                         //console.log('4) Speak into your microphone.');
                        this.pauseRecording()
                        //console.log("NOMATCH: Speech could not be recognized.");
                    }
            };

            this.recognizer.canceled = (s, e) => {
                //console.log(`CANCELED: Reason=${e.reason}`);

                if (e.reason == 0) {
                    //console.log(`"CANCELED: ErrorCode=${e.errorCode}`);
                    //console.log(`"CANCELED: ErrorDetails=${e.errorDetails}`);
                    //console.log("CANCELED: Did you update the subscription info?");
                }

                this.recognizer.stopContinuousRecognitionAsync();
            };

            this.recognizer.sessionStopped = () => {
                ////console.log(s+e)
                //console.log("\n    Session stopped event.");
                this.recognizer.stopContinuousRecognitionAsync();
            };
            
        }
        
    },
    beforeMount(){
        bus.$emit('checkMicPermission');
        this.questionservice('configuregraph/getallgparphs').then(()=>{
            this.questionLists = this.getQuestionList().data
            //var userdata = LocalStorageService.getUser()
            var userdata =this.$ls.get('user')
            this.userAnswer.user_id = userdata.user_id
            this.user_type = userdata.user_type
            this.currentUser=userdata
            this.userUsage()
        })
    }
}
</script>
<style scoped>
img{
width: 650px;
text-align: center;
margin: 0 auto;
display: block;
}
.heading
{
text-align: center!important;
color: #000 !important;
font-weight: 600 !important;
}
</style>